import Games from "./Games";
import GameResult from "./GameResult";
import "./App.css";
import { createClient } from "@supabase/supabase-js";
import React, { useState, useEffect } from "react";
import Athletes from "./Athletes";
import Coaches from "./Coaches";
import Stadiums from "./Stadiums";
import Cookies from "universal-cookie";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

export var isLogged = false;
export var supabase = null;

const cookies = new Cookies();
supabase = createClient(
  "https://rwfwafwtgealqsznzheg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ3ZndhZnd0Z2VhbHFzem56aGVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzA5NzMwODksImV4cCI6MjA0NjU0OTA4OX0.W8YfkHIAXmK76u54BdkOB_Sgme-YCVWNAFCn4DmfDyQ"
);

const Red = () => {
  const navigate = useNavigate();
  const [games, setGames] = useState([]);
  const [games_results, setGames_results] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [stadiums, setStadiums] = useState([]);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOverlayVisibleSign, setOverlayVisibleSign] = useState(false);
  const [isOverlayVisibleBlank, setOverlayVisibleBlank] = useState(false);
  const [isOverlayVisibleApplications, setOverlayVisibleApplications] = useState(false);
  const [selectedCoachAthlet, setSelectedCoachhAthlet] = useState(null);
  const [selectedStadiumGame, setSelectedStadiumGame] = useState(null);
  const [selectedFirstAthlete, setSelectedFirstAthlete] = useState(null);
  const [selectedSecondAthlete, setSelectedSecondAthlete] = useState(null);
  const [availableGames, setAvailableGames] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [searchGame, setSearchGame] = useState("");
  const [searchGame_Result, setSearchGame_Result] = useState("");
  const [searchAthlete, setSearchAthlete] = useState("");
  const [searchCoach, setSearchCoach] = useState("");
  const [searchStadium, setSearchStadium] = useState("");

  const searchGames = games.filter((game) =>
    game.Sport.toLowerCase().includes(searchGame.toLowerCase())
  );

  const searchGame_Results = games_results.filter((games_result) =>
    games_result.game_name.Sport.toLowerCase().includes(
      searchGame_Result.toLowerCase()
    )
  );

  const searchAthletes = athletes.filter((athlete) =>
    athlete.name.toLowerCase().includes(searchAthlete.toLowerCase())
  );

  const searchCoaches = coaches.filter((coach) =>
    coach.name.toLowerCase().includes(searchCoach.toLowerCase())
  );

  const searchStadiums = stadiums.filter((stadium) =>
    stadium.name.toLowerCase().includes(searchStadium.toLowerCase())
  );

  function openOverlaySign() {
    setOverlayVisibleSign(true);
  }

  function closeOverlaySign() {
    setOverlayVisibleSign(false);
  }

  function openOverlayBlank() {
    setOverlayVisibleBlank(true);
  }

  function closeOverlayBlank() {
    setOverlayVisibleBlank(false);
  }

  function openOverlayApplications() {
    setOverlayVisibleApplications(true);
  }

  function closeOverlayApplications() {
    setOverlayVisibleApplications(false);
  }

  function signOut() {
    isLogged = false;
    cookies.remove("logged");
    window.location.reload();
  }

  async function addToAthletes(id) {
    try {
      const { data, error: errorid } = await supabase
        .from("Applications_of_athletes")
        .select(
          `
          id,
          name,
          age,
          about,
          Coaches (id, name)
        `
        )
        .eq("id", id);

      if (errorid) console.log(errorid);
      deleteApplication(id);

      const { data: dataid } = await supabase
        .from("Athletes")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      const athlet = data[0];
      const { error } = await supabase
        .from("Athletes")
        .insert([
          {
            id: dataid[0].id + 1,
            name: athlet.name,
            age: athlet.age,
            coach_id: athlet.Coaches.id,
          },
        ])
        .select();
      window.location.reload();
    } catch (e) {
      console.log(e);
      swal("Ошибка!", "Не удалось добавить спортсмена", "error");
    }
  }

  async function deleteApplication(id) {
    try {
      const error = await supabase
        .from("Applications_of_athletes")
        .delete()
        .eq("id", id);
      if (error) {
        console.log(error);
      }
    } catch (e) {
      console.log(e);
    }

    try {
      const { data, error } = await supabase.from("Applications_of_athletes")
        .select(`
        id,
        name,
        age,
        about,
        Coaches (id, name)
      `);
      if (error) console.log(error);
      setApplications(data);
    } catch (e) {
      console.log(e);
    }
  }

  async function loginUser() {
    const login = document.getElementById("login");
    const passowrd = document.getElementById("password");
    if (login.value !== "" && passowrd.value !== "") {
      try {
        const { data, error } = await supabase.auth.signInWithPassword({
          email: login.value,
          password: passowrd.value,
        });
        if (error) console.log(error);
        if (data.session.access_token) {
          isLogged = true;
          cookies.set("logged", true);
          closeOverlaySign();
        }
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Неверный логин или пароль", "error");
      }
    }
    else{
      swal("Ошибка!", "Заполните все поля", "error");
    }
  }

  async function addStadium() {
    const name = document.getElementById("stadiumname");
    const capacity = document.getElementById("stadiumcapacity");
    const location = document.getElementById("stadiumlocation");
    if (name.value !== "" && capacity.value !== "" && location.value !== "") {
      const { data: dataid, error: errorid } = await supabase
        .from("Stadiums")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      if (errorid) console.log(errorid);

      try {
        const { error } = await supabase
          .from("Stadiums")
          .insert([
            {
              id: dataid[0].id + 1,
              name: name.value,
              capacity: capacity.value,
              location: location.value,
            },
          ])
          .select();
        if (error) console.log(error);
        else {
          const { data } = await supabase.from("Stadiums").select(`
              id,
              capacity,
              name,
              location
            `);
          setStadiums(data);
          name.value = "";
          capacity.value = "";
          location.value = "";
        }
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не удалось добавить стадион", "error");
      }
    }
    else{
      swal("Ошибка!", "Не удалось добавить стадион", "error");
    }
  }

  async function addApplication() {
    const Name = document.getElementById("FIO");
    const Age = document.getElementById("Age");
    const About = document.getElementById("About");
    if (Name !== "" && Age.value > 17 && About !== "") {
      try {
        const { error } = await supabase
          .from("Applications_of_athletes")
          .insert([
            {
              name: Name.value,
              age: Age.value,
              coach_id: selectedCoach,
              about: About.value,
            },
          ])
          .select();
        if (error) console.log(error);
        try {
          const { data, error } = await supabase.from(
            "Applications_of_athletes"
          ).select(`
            id,
            name,
            age,
            about,
            Coaches (id, name)
          `);
          if (error) console.log(error);
          setApplications(data);
        } catch (e) {
          console.log(e);
        }
        closeOverlayBlank();
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не отправить заявку", "error");
      }
    }
    else{
      swal("Ошибка!", "Не отправить заявку", "error");
    }
  }

  async function addCoach() {
    const name = document.getElementById("coachname");
    const age = document.getElementById("coachage");
    const experience = document.getElementById("coachexperience");
    if (name.value !== "" && age.value !== "" && experience.value !== "" && age.value > 17 && experience.value > -1) {
      const { data: dataid, error: errorid } = await supabase
        .from("Coaches")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      if (errorid) console.log(errorid);

      try {
        const { error } = await supabase
          .from("Coaches")
          .insert([
            {
              id: dataid[0].id + 1,
              name: name.value,
              age: age.value,
              experience: experience.value,
            },
          ])
          .select();
        if (error) console.log(error);
        else {
          const { data } = await supabase.from("Coaches").select(`
              id,
              age,
              name,
              experience
            `);
          setCoaches(data);
          name.value = "";
          age.value = "";
          experience.value = "";
        }
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не удалось добавить тренера", "error");
      }
    }
    else{
      swal("Ошибка!", "Не удалось добавить тренера", "error");
    }
  }

  async function addAthlet() {
    const name = document.getElementById("athletname");
    const age = document.getElementById("athletage");
    const coach = document.getElementById("athletcoach");
    if (name.value !== "" && age.value !== "" && selectedCoachAthlet !== "" && age.value > 17) {
      const { data: dataid, error: errorid } = await supabase
        .from("Athletes")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      if (errorid) console.log(errorid);

      try {
        const { error } = await supabase
          .from("Athletes")
          .insert([
            {
              id: dataid[0].id + 1,
              name: name.value,
              age: age.value,
              coach_id: selectedCoachAthlet,
            },
          ])
          .select();
        if (error) console.log(error);
        else {
          const { data } = await supabase.from("Athletes").select(`
              id,
              age,
              name,
              coach:Coaches!Athletes_coach_id_fkey(name)
            `);
          setAthletes(data);
          name.value = "";
          age.value = "";
          coach.value = "";
        }
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не удалось добавить спортсмена", "error");
      }
    }
    else{
      swal("Ошибка!", "Не удалось добавить спортсмена", "error");
    }
  }

  async function addGame_Result() {
    const gamename = document.getElementById("game_resultgameName");
    const firstscore = document.getElementById("game_resultfirstathlet");
    const secondscore = document.getElementById("game_resultsecondathlet");
    const gamedate = document.getElementById("game_resultgameDate");
    if (
      selectedGame !== "" &&
      firstscore.value !== "" &&
      secondscore.value !== "" &&
      selectedDate !== "" &&
      firstscore.value > -1 &&
      secondscore.value > -1
    ) {
      const { data: dataid, error: errorid } = await supabase
        .from("Game_Results")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);
      if (errorid) console.log(errorid);
      try {
        const gid = games.find(
          (game) =>
            game.game_date === selectedDate && game.Sport === selectedGame
        );

        const { data: result, error: errorResult } = await supabase
          .from("Game_Results")
          .select("*")
          .eq("game_id", gid.id);

        if (errorResult) {
          console.log(errorResult);
          return;
        }

        if (result.length > 0) {
          swal("Ошибка!", "Для данной игры уже существует результат.", "error");
          return;
        }

        const { error } = await supabase
          .from("Game_Results")
          .insert([
            {
              id: dataid[0].id + 1,
              game_id: gid.id,
              athlet1_score: firstscore.value,
              athlet2_score: secondscore.value,
            },
          ])
          .select();
        if (error) console.log(error);
        else {
          const { data } = await supabase.from("Game_Results").select(`
              id,
              athlet1_score,
              athlet2_score,
              game_name:Games!Game_Results_game_id_fkey(Sport,game_date)
            `);
          setGames_results(data);
          setSelectedDate(null);
          setSelectedGame(null);
          firstscore.value = "";
          secondscore.value = "";
          gamedate.value = "";
          gamename.value = "";
        }
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не удалось добавить результаты матча", "error");
      }
    } else {
      swal("Ошибка!", "Не удалось добавить результаты матча", "error");
    }
  }

  async function addGame() {
    const name = document.getElementById("gameName");
    const firstathlet = document.getElementById("firstathlet");
    const secondathlet = document.getElementById("secondathlet");
    const gamedate = document.getElementById("gameDate");
    const rounds = document.getElementById("rounds_of_number");
    const stadiumid = document.getElementById("gamestadium");
    if (
      name.value !== "" &&
      gamedate.value !== "" &&
      rounds.value !== "" &&
      selectedFirstAthlete !== "" &&
      selectedSecondAthlete !== "" &&
      selectedStadiumGame !== "" &&
      rounds.value > 0
    ) {
      const { data: dataid, error: errorid } = await supabase
        .from("Games")
        .select("id")
        .order("id", { ascending: false })
        .limit(1);

      if (errorid) console.log(errorid);
      try {
        const { error } = await supabase
          .from("Games")
          .insert([
            {
              id: dataid[0].id + 1,
              athletes1_id: selectedFirstAthlete,
              athletes2_id: selectedSecondAthlete,
              game_date: gamedate.value,
              stadium_id: selectedStadiumGame,
              Sport: name.value,
              number_of_rounds: rounds.value,
            },
          ])
          .select();
        if (error) console.log(error);
        else {
          const { data } = await supabase.from("Games").select(`
              id,
              game_date, 
              number_of_rounds, 
              Sport, 
              athlete1:Athletes!Games_athletes1_id_fkey(name),
              athlete2:Athletes!Games_athletes2_id_fkey(name),
              stadium:Stadiums!Games_stadium_id_fkey(name)
            `);
          setGames(data);
          name.value = "";
          firstathlet.value = "";
          secondathlet.value = "";
          gamedate.value = "";
          rounds.value = "";
          stadiumid.value = "";
        }
      } catch (e) {
        console.log(e);
        swal("Ошибка!", "Не удалось добавить игру", "error");
      }
    }
    else{
      swal("Ошибка!", "Не удалось добавить игру", "error");
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const [games, gamesResults, athletes, coaches, stadiums, applications] =
          await Promise.all([
            supabase.from("Games").select(`
            id,
            game_date, 
            number_of_rounds, 
            Sport, 
            athlete1:Athletes!Games_athletes1_id_fkey(id, name),
            athlete2:Athletes!Games_athletes2_id_fkey(id, name),
            stadium:Stadiums!Games_stadium_id_fkey(id, name)
          `),
            supabase.from("Game_Results").select(`
            id,
            athlet1_score,
            athlet2_score,
            game_name:Games!Game_Results_game_id_fkey(Sport,game_date)
          `),
            supabase.from("Athletes").select(`
            id,
            age,
            name,
            coach:Coaches!Athletes_coach_id_fkey(id, name)
          `),
            supabase.from("Coaches").select(`
            id,
            age,
            name,
            experience
          `),
            supabase.from("Stadiums").select(`
            id,
            capacity,
            name,
            location
          `),
            supabase.from("Applications_of_athletes").select(`
            id,
            name,
            age,
            about,
            Coaches (id, name)
          `),
          ]);

        setGames(games.data);
        setGames_results(gamesResults.data);
        setAthletes(athletes.data);
        setCoaches(coaches.data);
        setStadiums(stadiums.data);
        setApplications(applications.data);
      } catch (error) {
        console.error("Ошибка загрузки данных:", error);
      } finally {
        setLoading(false);
      }
    }
    function LoadCookies() {
      if (cookies.get("logged")) {
        isLogged = true;
      }
    }
    fetchData();
    LoadCookies();
  }, []);

  useEffect(() => {
    setAvailableGames([...new Set(games.map((game) => game.Sport))]);
    setAvailableDates([
      ...new Set(
        games
          .filter((game) => game.Sport === selectedGame)
          .map((game) => game.game_date)
      ),
    ]);
  }, [selectedGame, selectedDate, games]);

  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar">
          <ul className="nav_redirection">
            <li>
              <a href="#games">Игры</a>
            </li>
            <li>
              <a href="#games_results">Результаты игр</a>
            </li>
            <li>
              <a href="#athletes">Спортсмены</a>
            </li>
            <li>
              <a href="#coaches">Тренера</a>
            </li>
            <li>
              <a href="#stadiums">Стадионы</a>
            </li>
            <li>
              <a onClick={() => openOverlayBlank()}>Хотите поучаствовать?</a>
            </li>
          </ul>

          {isLogged ? (
            <div className="log_button">
              <button onClick={() => openOverlayApplications()}>
                Полученные заявки
              </button>
              <button onClick={() => signOut()}>Выйти из аккаунта</button>
            </div>
          ) : (
            <div className="log_button">
              <button onClick={() => openOverlaySign()}>Войти</button>
            </div>
          )}
        </nav>
      </header>

      <div className="App-content">
        {isOverlayVisibleSign && (
          <div className="overlay">
            <div className="overlay-content_signin">
              <h2>Вход в аккаунт</h2>
              <p className="Field_login">
                Логин: <input id="login" className="login" />
              </p>
              <p className="Field_login">
                Пароль:{" "}
                <input id="password" type="password" className="password" />
              </p>
              <button onClick={() => loginUser()}>Войти</button>
              <button onClick={() => closeOverlaySign()}>Закрыть</button>
            </div>
          </div>
        )}

        {isOverlayVisibleBlank && (
          <div className="overlay">
            <div className="overlay-content_blank">
              <h2>Бланк регистрации спортсмена</h2>
              <p className="Field_blank">
                Введите ФИО: <input id="FIO" className="FIO" />
              </p>
              <p className="Field_blank">
                Введите возраст:{" "}
                <input id="Age" className="Age" min="0" type="number" />
              </p>
              <p className="Field_blank">
                Выберите желаемого тренера:{" "}
                <select
                  id="Coach_desired"
                  сlassName="Coach_desired"
                  onChange={(coach) => setSelectedCoach(coach.target.value)}
                >
                  <option value="">Выберите тренера</option>
                  {coaches.map((coach) => (
                    <option value={coach.id}>{coach.name}</option>
                  ))}
                </select>
              </p>
              <p className="Field_blank">
                Расскажите о себе:{" "}
                <textarea id="About" className="About" rows="3" />
              </p>
              <button onClick={() => addApplication()}>Отправить заявку</button>
              <button onClick={() => closeOverlayBlank()}>Закрыть</button>
            </div>
          </div>
        )}

        {isOverlayVisibleApplications && (
          <div className="overlay">
            <div className="overlay-content_applications">
              <h2>Полученные заявки</h2>
              <ul className="overlay_ul">
                {applications.map((app) => (
                  <li key={app.id}>
                    <strong>Фио</strong> {app.name} <strong>Возраст</strong>
                    {app.age} <strong>О кандидате</strong>
                    {app.about} <strong>Желаемый тренер</strong>
                    {app.Coaches.name}
                    <button onClick={() => addToAthletes(app.id)}>
                      Добавить к спортсменам
                    </button>
                    <button onClick={() => deleteApplication(app.id)}>
                      Удалить
                    </button>
                  </li>
                ))}
              </ul>
              <button onClick={() => closeOverlayApplications()}>
                Закрыть
              </button>
            </div>
          </div>
        )}

        <h1 className="h1tittle" id="games">
          Игры
        </h1>
        <input
          type="search"
          className="Search_game"
          placeholder="Поиск"
          onChange={(e) => setSearchGame(e.target.value)}
        />
        <div className="view">
          {loading ? (
            <h1>Loading</h1>
          ) : (
            <>
              {searchGames.map((game) => (
                <Games
                  id={game.id}
                  sport={game.Sport}
                  athlet1={game.athlete1}
                  athlet2={game.athlete2}
                  date={game.game_date}
                  round_number={game.number_of_rounds}
                  stadium={game.stadium}
                  athletes={athletes}
                  stadiums={stadiums}
                />
              ))}
              {isLogged && (
                <div className="card">
                  <h2 className="tittle">
                    <strong>Название спорта:</strong> <input id="gameName" />
                  </h2>
                  <p className="info">
                    <strong>Первый спортсмен:</strong>{" "}
                    <select
                      id="firstathlet"
                      onChange={(athlete) =>
                        setSelectedFirstAthlete(athlete.target.value)
                      }
                    >
                      <option value="">Выберите спортсмена</option>
                      {athletes.map((athlete) => (
                        <option value={athlete.id}>{athlete.name}</option>
                      ))}
                    </select>
                  </p>
                  <p className="info">
                    <strong>Второй спортсмен:</strong>{" "}
                    <select
                      id="secondathlet"
                      onChange={(athlete) =>
                        setSelectedSecondAthlete(athlete.target.value)
                      }
                    >
                      <option value="">Выберите спортсмена</option>
                      {athletes.map((athlete) => (
                        <option value={athlete.id}>{athlete.name}</option>
                      ))}
                    </select>
                  </p>
                  <p className="info">
                    <strong>Дата:</strong> <input id="gameDate" type="date" />
                  </p>
                  <p className="info">
                    <strong>Количество раундов:</strong>{" "}
                    <input id="rounds_of_number" type="number" min="1" />
                  </p>
                  <p className="info">
                    <strong>Стадион:</strong>{" "}
                    <select
                      id="gamestadium"
                      onChange={(stadium) =>
                        setSelectedStadiumGame(stadium.target.value)
                      }
                    >
                      <option value="">Выберите стадион</option>
                      {stadiums.map((stadium) => (
                        <option value={stadium.id}>{stadium.name}</option>
                      ))}
                    </select>
                  </p>
                  <button className="supp_button" onClick={() => addGame()}>
                    Добавить
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        <h1 className="h1tittle" id="games_results">
          Результаты игр
        </h1>
        <input
          type="search"
          className="Search_game_results"
          placeholder="Поиск"
          onChange={(e) => setSearchGame_Result(e.target.value)}
        />
        <div className="view">
          {loading ? (
            <h1>Loading</h1>
          ) : (
            <>
              {searchGame_Results.map((games_results) => (
                <GameResult
                  id={games_results.id}
                  sport={games_results.game_name.Sport}
                  athlet1={games_results.athlet1_score}
                  athlet2={games_results.athlet2_score}
                  date={games_results.game_name.game_date}
                  games={games}
                />
              ))}
              {isLogged && (
                <div className="card">
                  <h2 className="tittle">
                    <strong>Название спорта:</strong>{" "}
                    <select
                      id="game_resultgameName"
                      onChange={(game) => setSelectedGame(game.target.value)}
                    >
                      <option value="">Выберите спорт</option>
                      {availableGames.map((game) => (
                        <option value={game}>{game}</option>
                      ))}
                    </select>
                  </h2>
                  <p className="info">
                    <strong>Счёт первого спортсмена:</strong>{" "}
                    <input id="game_resultfirstathlet" type="number" min="0" />
                  </p>
                  <p className="info">
                    <strong>Счёт второго спортсмена:</strong>{" "}
                    <input id="game_resultsecondathlet" type="number" min="0" />
                  </p>
                  <p className="info">
                    <strong>Дата:</strong>{" "}
                    <select
                      onChange={(date) => setSelectedDate(date.target.value)}
                      id="game_resultgameDate"
                    >
                      <option value="">Выберите дату</option>
                      {availableDates.map((date) => (
                        <option value={date}>{date}</option>
                      ))}
                    </select>
                  </p>
                  <button
                    className="supp_button"
                    onClick={() => addGame_Result()}
                  >
                    Добавить
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        <h1 className="h1tittle" id="athletes">
          Спортсмены
        </h1>
        <input
          type="search"
          className="Search_athlete"
          placeholder="Поиск"
          onChange={(e) => setSearchAthlete(e.target.value)}
        />
        <div className="view">
          {loading ? (
            <h1>Loading</h1>
          ) : (
            <>
              {searchAthletes.map((athlete) => (
                <Athletes
                  id={athlete.id}
                  name={athlete.name}
                  age={athlete.age}
                  coach={athlete.coach}
                  coaches={coaches}
                />
              ))}
              {isLogged && (
                <div className="card">
                  <h2 className="tittle">
                    <strong>Фио спортсмена:</strong> <input id="athletname" />
                  </h2>
                  <p className="info">
                    <strong>Возраст спортсмена:</strong>{" "}
                    <input id="athletage" type="number" />
                  </p>
                  <p className="info">
                    <strong>Тренер:</strong>{" "}
                    <select
                      id="athletcoach"
                      onChange={(coach) =>
                        setSelectedCoachhAthlet(coach.target.value)
                      }
                    >
                      <option value="">Выберите тренера</option>
                      {coaches.map((coach) => (
                        <option value={coach.id}>{coach.name}</option>
                      ))}
                    </select>
                  </p>
                  <button className="supp_button" onClick={() => addAthlet()}>
                    Добавить
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        <h1 className="h1tittle" id="coaches">
          Тренера
        </h1>
        <input
          type="search"
          className="Search_coach"
          placeholder="Поиск"
          onChange={(e) => setSearchCoach(e.target.value)}
        />
        <div className="view">
          {loading ? (
            <h1>Loading</h1>
          ) : (
            <>
              {searchCoaches.map((coach) => (
                <Coaches
                  id={coach.id}
                  name={coach.name}
                  age={coach.age}
                  experience={coach.experience}
                />
              ))}

              {isLogged && (
                <div className="card">
                  <h2 className="tittle">
                    <strong>Фио тренера:</strong> <input id="coachname" />
                  </h2>
                  <p className="info">
                    <strong>Возраст:</strong>{" "}
                    <input id="coachage" type="number" min="0" />
                  </p>
                  <p className="info">
                    <strong>Опыт работы:</strong>{" "}
                    <input id="coachexperience" type="number" min="0" />
                  </p>
                  <button className="supp_button" onClick={() => addCoach()}>
                    Добавить
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        <h1 className="h1tittle" id="stadiums">
          Стадионы
        </h1>
        <input
          type="search"
          className="Search_stadium"
          placeholder="Поиск"
          onChange={(e) => setSearchStadium(e.target.value)}
        />
        <div className="view">
          {loading ? (
            <h1>Loading</h1>
          ) : (
            <>
              {searchStadiums.map((stadium) => (
                <Stadiums
                  id={stadium.id}
                  capacity={stadium.capacity}
                  name={stadium.name}
                  location={stadium.location}
                />
              ))}
              {isLogged && (
                <div className="card">
                  <h2 className="tittle">
                    <strong>Название стадиона:</strong>{" "}
                    <input id="stadiumname" />
                  </h2>
                  <p className="info">
                    <strong>Вместимость:</strong>{" "}
                    <input id="stadiumcapacity" type="number" min="1" />
                  </p>
                  <p className="info">
                    <strong>Местоположение:</strong>{" "}
                    <input id="stadiumlocation" />
                  </p>
                  <button className="supp_button" onClick={() => addStadium()}>
                    Добавить
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="App-footer">
        <ul className="Footer_ul">
          <li onClick={() => navigate("/report")}>Сгенерировать отчёт ®</li>
        </ul>
      </div>
    </div>
  );
};

export default Red;
