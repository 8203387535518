import React, { useState, useEffect } from "react";
import { supabase } from "./Red";

const Report = () => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGames = async () => {
      setLoading(true);
      const { data, error } = await supabase
        .from("Games")
        .select(
          `
            id,
            game_date, 
            number_of_rounds, 
            Sport, 
            athlete1:Athletes!Games_athletes1_id_fkey(id, name),
            athlete2:Athletes!Games_athletes2_id_fkey(id, name),
            stadium:Stadiums!Games_stadium_id_fkey(id, name, location)
        `
        )
        .order("game_date", { ascending: true });

      if (error) {
        console.error("Ошибка загрузки игр:", error);
      } else {
        setGames(data);
      }
      setLoading(false);
    };

    fetchGames();
  }, []);

  if (loading) {
    return <div>Загрузка отчета...</div>;
  }

  if (games.length === 0) {
    return <div>Нет данных для отчета</div>;
  }

  return (
    <div className="report">
      <h1>Отчет: Расписание игр</h1>
      <p>
      {games.length === 0 ? (
        <p>Нет данных для отображения</p>
      ) : (
        <pre>{JSON.stringify(games, null, 2)}</pre>
      )}
      </p>
    </div>
  );
};

export default Report;